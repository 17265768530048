import React from 'react'
import Logo from '../images/logo.svg'
import { useHistory } from 'react-router-dom'

const EoLogo = () => {

  const history = useHistory();

  const handleRedirect = (e) => {
    e.preventDefault();
    history.push('/login');
  }

  return (
    <div className='flex items-end cursor-pointer' onClick={handleRedirect}>
      <img src={Logo} className='w-20' alt='Eo logo' />
      <p className='text-2xl font-bold text-gray-800 ml-1'>
        <span className='text-4xl'>EONJ</span>
        <br />
        <span className='text-red-400' style={{width: '264px'}}>LUGGAGE TAGS</span>
      </p>
    </div>
  )
}

export default EoLogo
