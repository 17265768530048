import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userInformation, singoutUser } from '../Auth/AuthSlice';
import { UserDetails, EoLogo } from '../../Components';
import { Button } from '@progress/kendo-react-buttons';
import ScanHistory from './ScanHistory';
import Banner from '../../Components/Banner';
import VCardDetails from './VCardDetails';
import { setVCardInformation, vCardInformation } from './ProfileSlice';
import * as api from '../../../api';
import { red } from 'tailwindcss/colors';

const Profile = () => {
    
    const reduxUserInformation = useSelector(userInformation);
    const reduxVCardInformation = useSelector(vCardInformation)
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSignOut = (e) => { 
        dispatch(singoutUser());
        history.go(0);
    }
    React.useEffect(()=>{
        api.getVCardInformation(reduxUserInformation.id).then((res)=>{

            dispatch(setVCardInformation((res.data=== "")? null: res.data))
        })
    }, []);
    

    return(
        <div className='w-full min-h-screen flex p-8 landing-bg justify-center items-center'>
            <div className='w-full p-8 md:p-12 bg-white max-w-6xl rounded-lg shadow-lg flex-col flex-wrap justify-center lg:justify-between relative'>
                <div className='my-4 flex justify-between mb-8'>
                    <div>
                        <EoLogo />
                    </div>
                    <div className='flex items-center'>
                        <Button primary={true} onClick={handleSignOut}>Log Out</Button>
                    </div>
                </div>
                <div>
                    <UserDetails userInfo={reduxUserInformation} isLoggedInUser={true}/>
                    <VCardDetails userInfo={reduxVCardInformation} isLoggedInUser={true}/>
                    {reduxUserInformation.id && <ScanHistory/>}
                </div>
                <Banner/>
            </div>
        </div>
    );
}

export default Profile;