//imports required by the module
import React from "react";
import { useSelector } from "react-redux";
import {
  user,
} from "./HomeSlice";
import { UserDetails } from "../../Components";
import RedirectByStatus from "./RedirectByStatus";
import LocationDisclaimer from "./LocationDisclaimer";

const Home = () => {
  const userInformation = useSelector(user);

  return (
    <RedirectByStatus>
      <>
        { userInformation?.id ? (
          <UserDetails userInfo={userInformation} />
        ): <LocationDisclaimer/>}
      </>
    </RedirectByStatus>
  );
};

export default Home;
