import React, { useState} from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { useDispatch, useSelector } from "react-redux";
import { sendNotification } from './HomeSlice';
import {getAddress} from '../../../api';

function LocationDisclaimer() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [locationError, setLocationError] = useState('')
    const handleShowInformation = async () => {
        setIsLoading(true);
        const location = await getLocation(); //checking if the user's location could be determined
        const address = await getAddressFromLatLang(location.latitude, location.longitude);
        if (location != null) {
            if(location?.latitude && location?.longitude) { 
                let payload = {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    address: address
                  };            
                  dispatch(sendNotification(payload));
            }

            setIsLoading(false);
            setLocationError('');
        } else {
            setIsLoading(false);
            setLocationError('Can\'t get location access. Please change your privacy settings.');
        }
      };

      const getLocation = () => {
        return new Promise((resolve, reject) => {
          if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                if (position?.coords?.latitude && position?.coords?.longitude) {
                  let payload = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  };
    
                  resolve(payload);
                } else {
                  resolve(null);
                }
              },
              (error) => {
                resolve(null);
              }
            );
          } else {
            resolve(null);
          }
        });
      };
      const getAddressFromLatLang = (lat, lang) => {
        return new Promise((resolve, reject) => {
          getAddress(lat, lang).then((res)=>{
            if(res?.data?.results){
              resolve(res.data.results[0].formatted_address);
            } else{
              setLocationError('Failed to determine address. Please try again later.');
            }
          }).catch(()=>{
            reject();
            setLocationError('Failed to determine address. Please try again later.');
          })
        });
      };

  return (
    <div className="mb-8 max-w-6xl">
      {locationError.length > 0 && 
        <Error>{locationError}</Error>}
      <div className="mb-3">
        <span className="text-gray-700">
          Press the button below to notify the owner.
        </span>
      </div>
      <div>
        <Button primary={true} onClick={handleShowInformation} disabled={isLoading}>
          Show me owner's Information
        </Button>
      </div>
      <Error><br/><br/>
        *Please allow location access when prompted.
      </Error>
    </div>
  );
}

export default LocationDisclaimer;
