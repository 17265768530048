import React from 'react'
import { Error } from '@progress/kendo-react-labels'
import { Input } from '@progress/kendo-react-inputs'

const ValidatedInput = (props) => {
    const { validationMessage, modified, ...others } = props
    return (
        <>
            <Input {...others} />
            {modified && validationMessage && <Error>{validationMessage}</Error>}
        </>
    )
}
export default ValidatedInput;