import React from 'react';
import Routes from './app/Routing/Routes';
import '@progress/kendo-theme-default/dist/all.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Authorized } from './app/screens/Auth';

function App() {
  return (
    <Router>
      <Authorized>
        <Routes />
      </Authorized>
    </Router>
  );
}

export default App;
