import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@progress/kendo-react-buttons'

const SuccessText = () => {

    const history = useHistory();

    const handleClick = () => {
        history.push('/login');
    }

    return (
        <div>
            <p className='text-lg md:text-2xl xl:text-4xl font-bold text-gray-800'>
                Congratulations!
            </p>
            <p className='text-md md:text-lg xl:text-xl text-gray-600 py-4'>
                Your account has been successfully created.
                <br></br>
                When your tag is scanned, your contact info appears and you'll get an email with it's location.
                <br></br>
                <br></br>
                Come back to <a href="tag.eonewjersey.org" className="text-blue-600">tag.eonewjersey.org</a> to check your tag location history or edit your profile.
                <br></br>         
                <Button
                    primary={true}
                    onClick={handleClick}
                    className='my-4'
                >
                    Continue
                </Button>
            </p>
        </div>
    )
}

export default SuccessText
