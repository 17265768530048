import React from 'react'
import { useDispatch } from 'react-redux'
import { emailValidator, passwordValidator } from '../../Utilities'
import { Button } from '@progress/kendo-react-buttons'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { loginUser, setErrorMessage } from './LandingSlice'
import { ValidatedInput } from '../../Components'
import {useHistory} from 'react-router-dom';

const LoginForm = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = async dataItem => {
    dispatch(loginUser(dataItem));
  }; 

  return (
    <Form
      onSubmit={handleSubmit}
      render={formRenderProps => (
        <FormElement>
          <div className='mb-3' onClick={()=>dispatch(setErrorMessage(null))}>
            <Field
              type={'email'}
              name={'emailAddress'}
              label='Email Address'
              component={ValidatedInput}
              validator={emailValidator}
            />
          </div>
          <div className='mb-3' onClick={()=>dispatch(setErrorMessage(null))}>
            <Field
              type={'password'}
              name={'password'}
              label='Password'
              component={ValidatedInput}
              validator={passwordValidator}
            />
          </div>
          <div className='mb-3 flex justify-between items-end'>
            <Button
              primary={true}
              type={'submit'}
              disabled={!formRenderProps.valid}
            >
              Login
            </Button>
            <p tabIndex="0" className="text-right text-primary underline italic cursor-pointer" onClick={()=>history.push('/forgot-password')}>Forgot Password?</p>
          </div>
        </FormElement>
      )}
    />
  )
}

export default LoginForm
