import React from 'react'
import { Loader } from "@progress/kendo-react-indicators";

function LoaderScreen() {
    return (
        <div className="w-full min-h-screen flex items-center p-8 landing-bg justify-center">
            <Loader size="large" themeColor="light"/>
        </div>
    )
}

export default LoaderScreen
