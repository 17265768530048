import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import registrationImage from '../../images/registration.svg'
import { EoLogo } from '../../Components';
import { Error } from '@progress/kendo-react-labels'
import RegistrationText from './RegistrationText';
import RegistrationForm from './RegistrationForm';
import SuccessText from './SuccessText';
import { isRegistered, registrationError } from './RegisterSlice';
import Banner from '../../Components/Banner';


const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackerId } = useParams();
  const reduxIsRegisterd = useSelector(isRegistered);
  const reduxErrorMessage = useSelector(registrationError);
  const[registrationCompleted, setRegistrationCompleted] = useState(false);

  useEffect(() => {

    if(reduxIsRegisterd) {
     setRegistrationCompleted(true);
    }

  },[reduxIsRegisterd]);

  const isTrackerValid = (id) =>{
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const str = id
    if(regexExp.test(str)){
      return true
    } else {
      return false
    }
  }
  const goToError = () =>{
    history.push('/error')
  }
  return (
      <div className='w-full min-h-screen flex items-center p-8 landing-bg'>
        <div className='w-full p-8 md:p-12 bg-white max-w-6xl rounded-lg shadow-lg flex flex-wrap justify-center lg:justify-between mx-auto relative'>
          <div className='my-4'>
            <div className='mb-8'>
              <EoLogo />
            </div>
            <img
              src={registrationImage}
              className='max-w-md w-full'
              alt='baggage search illustration'
            />
          </div>
          <div className='max-w-sm flex-grow my-4 flex flex-col justify-center'>
            {registrationCompleted 
              ?  
              <SuccessText />
              : 
              <>
                <RegistrationText />
                <div>
                  {reduxErrorMessage?.length > 0 && <Error>{reduxErrorMessage}</Error>}
                  {isTrackerValid(trackerId) ? <RegistrationForm  trackerId={trackerId}/>
                  :
                  goToError()}
                </div>
              </>
            }
          </div>
          <Banner/>
        </div>
      </div>
  )}

export default Register
