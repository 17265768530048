import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../../api';

const initialState = {
    errorMessage: null,
    trackerId: null,
    user: null
};

export const HomeSlice = createSlice({
    name: 'home',
    initialState,

    reducers: {
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setTrackerId: (state, action)=> {
            state.trackerId = action.payload;
        }
    }
});
export const registered = (state) => state.home.registered;
export const errorMessage = (state) => state.home.errorMessage;
export const trackerId = (state)=> state.home.trackerId;
export const user = (state) => state.home.user;

export const sendNotification = (payload) => (dispatch, getState) => {
    let id = getState().home.trackerId;
    payload={...payload, trackerId: id};

    api.sendNotification(payload).then((res) => {
        dispatch(getUserInformation(id));
    }).catch((error) => {
        dispatch(setErrorMessage('Something went wrong. Please try again'));
    })
}

export const hasUserRegistered = (id) => (dispatch) => {
    api.hasUserRegistered(id).then((res) => {
        if(res.data===true) {         
            dispatch(setTrackerId(id));
        } else{
            dispatch(setTrackerId(-1));
        }
    }).catch((error) => {
        dispatch(setTrackerId(-1));
        dispatch(setErrorMessage('Something went wrong while retrieving user information. Please try refreshing the page.'));
    });
}

export const getUserInformation = (payload) => (dispatch) => {
    api.getUserInformation(payload).then((res) => {
        dispatch(setUser(res.data));
    }).catch((e) => {
        dispatch(setUser(null));
        dispatch(setErrorMessage('Something went wrong while retrieving user information. Please try refreshing the page.'))
    })
}

export const { setErrorMessage, setUser, setTrackerId } = HomeSlice.actions;
export default HomeSlice.reducer;