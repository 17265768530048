import axios from "axios";
let instance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
});

instance.interceptors.request.use(
  async function (config) {
    config.headers.Authorization =
      "Bearer " + localStorage.getItem("login_token");
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export const getUserInformation = (id) => {
  //api to get property owner's information
  return instance.get(`api/information?id=${id}`);
};

export const getVCardInformation = (id) =>{
  return instance.get(`api/user/${id}/VCardInformation`);
}
export const updateVCardInformation = (vCardInfo)=>{
  return instance.post("api/user/VCardInformation", vCardInfo);  
}

export const sendNotification = (req) => {
  //api to send email to property owner to notify about the location where their belonging was scanned
  return instance.post("api/notify", req);
};

export const hasUserRegistered = (id) => {
  return instance.get(`api/registered?id=${id}`);
};

export const registerUser = (user) => {
  return instance.post(`api/register`, user);
};

export const login = (req) => {
  return instance.post(`api/login`, req);
};

export const loginWithToken = () => {
  return instance.get(`api/login-token`);
};

export const getScanHistory = (id) => {
  return instance.get(`api/scan-history?trackerId=${id}`);
};

export const updateUser = (user) => {
  return instance.post(`api/user`, user);
};

export const forgotPassword = (payload) => {
  return instance.get(`api/forgot-password?emailAddress=${payload}`);
};

export const resetPassword = ({ token, password }) => {
  let newInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
  });

  newInstance.interceptors.request.use(
    async function (config) {
      config.headers.Authorization = "Bearer " + token;
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );
  return newInstance.get(`api/reset-password?password=${password}`);
};

export const getAddress =(lat, lang) => {
  let newInstance = axios.create({
    baseURL: `https://maps.googleapis.com/maps/api/geocode/`,
  })
  return newInstance.get(`json?latlng=${lat},${lang}&key=${process.env.REACT_APP_GOOGLE_KEY}`);
}
