import React from "react";
import { EoLogo } from "../../Components";
import ForgotPasswordImage from "../../images/forgotPassword.svg";
import { useDispatch, useSelector } from "react-redux";
import { emailValidator } from "../../Utilities";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { ValidatedInput } from "../../Components";
import { useHistory } from "react-router-dom";
import { forgotPassword, setIsSuccess, error, isSuccess } from "../Auth/AuthSlice";
import { Error } from "@progress/kendo-react-labels";

function ForgotPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const _error = useSelector(error);
  const _isSuccess = useSelector(isSuccess);

  const handleSubmit = async (dataItem) => {
    dispatch(forgotPassword(dataItem));
  };

  return (
    <div className="w-full min-h-screen flex items-center p-8 landing-bg">
      <div className="w-full p-8 md:p-12 bg-white max-w-2xl rounded-lg shadow-lg flex flex-col md:flex-row justify-center lg:justify-between mx-auto">
        <div className="my-4">
          <div className="mb-8">
            <EoLogo />
          </div>
          {!_isSuccess && <div className="w-64 mx-4">
            <img
              src={ForgotPasswordImage}
              className="w-full"
              alt="password forgot illustration"
            />
          </div>}
        </div>
        <div className="flex-grow m-4 flex flex-col justify-center">
          {!_isSuccess ? (
            <>
              <p className="text-lg md:text-xl font-bold text-gray-800">
                Forgot Password
              </p>
              {_error.length > 0 && <Error>{_error}</Error>}
              <Form
                onSubmit={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    <div className="mb-3">
                      <Field
                        type={"email"}
                        name={"emailAddress"}
                        label="Email Address"
                        component={ValidatedInput}
                        validator={emailValidator}
                      />
                    </div>
                    <div className="mb-3 flex justify-between items-end">
                      <Button
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        Submit
                      </Button>
                      <p
                        tabIndex="0"
                        className="text-right text-primary underline italic cursor-pointer"
                        onClick={() => {
                          history.push("/login");
                          dispatch(setIsSuccess(false));
                        }}
                      >
                        Login
                      </p>
                    </div>
                  </FormElement>
                )}
              />
            </>
          ) : (
            <>
              <p className="md:text-lg font-bold text-gray-800 text-center">
                Please check your email to reset your password.
              </p>
              <p
                tabIndex="0"
                className="text-center underline cursor-pointer uppercase text-xl tex-blue-500"
                onClick={() => {
                  history.push("/login");
                  dispatch(setIsSuccess(false));
                }}
              >
                Back to login
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
