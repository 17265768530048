import React , {useRef} from 'react'
import { useDispatch } from 'react-redux'
import { comparePassword, emailValidator, passwordValidator, phoneNumberValidator, zipCodeValidator } from '../../Utilities'
import { Button } from '@progress/kendo-react-buttons'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Input } from '@progress/kendo-react-inputs'
import { ValidatedInput } from '../../Components'
import { registerUser, setRegistrationError } from './RegisterSlice'
import ReCAPTCHA from 'react-google-recaptcha';
import { DropDownList } from '@progress/kendo-react-dropdowns'

export  const statesList = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];
    
const RegistrationForm = ({ trackerId }) => {

    const dispatch = useDispatch();
    const recaptchaRef = useRef(null)
     const [states, setStates] = React.useState('Select a State.');

     const handleChange = (event) => {
        setStates(event.target.value);
      };
    
    const handleSubmit =  async (dataItem) => {
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        dataItem.Token = token;
        let payload = JSON.parse(JSON.stringify(dataItem));
        delete payload.confirmPassword;
        payload.Id = trackerId;
        payload.State = states;
        dispatch(registerUser(payload));
    };

    return (
        <Form
            onSubmit={handleSubmit}
            render={formRenderProps => (
                <FormElement>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'text'}
                            name={'firstName'}
                            label='First Name'
                            component={Input}
                        />
                    </div>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'text'}
                            name={'lastName'}
                            label='Last Name'
                            component={Input}
                        />
                    </div>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'email'}
                            name={'emailAddress'}
                            label='Email Address'
                            component={ValidatedInput}
                            validator={emailValidator}
                        />
                    </div>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'text'}
                            name={'address'}
                            label='Address'
                            component={Input}
                        />
                    </div>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'text'}
                            name={'city'}
                            label='City'
                            component={Input}
                        />
                    </div>
                    <div className='mb-6' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'text'}
                            name={'zipCode'}
                            label='Zip Code'
                            component={ValidatedInput}
                            validator={zipCodeValidator}
                        />
                    </div>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <DropDownList data={statesList} value={states} onChange = {handleChange}/>
                    </div>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'text'}
                            name={'phoneNumber'}
                            label='Phone Number'
                            component={ValidatedInput}
                            validator={phoneNumberValidator}
                        />
                    </div>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'password'}
                            name={'password'}
                            label='Password'
                            component={ValidatedInput}
                            validator={passwordValidator}
                        />
                    </div>
                    <div className='mb-3' onClick={() => dispatch(setRegistrationError(null))}>
                        <Field
                            type={'password'}
                            name={'confirmPassword'}
                            label='Confirm Password'
                            component={ValidatedInput}
                            validator={(value) => comparePassword(formRenderProps.valueGetter('password'), value)}
                        />
                    </div>
                    <div className='mb-3'>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA} 
                    size="invisible"
                    ref = {recaptchaRef}
                    />
                        <Button
                            primary={true}
                            type={'submit'}
                            disabled={!formRenderProps.valid || states === 'Select a State.'}
                        >
                            Register
                        </Button>
                    </div>
                </FormElement>
            )}
        />
    )
}

export default RegistrationForm;