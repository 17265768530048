import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { mobileValidator, phoneNumberValidator, websiteValidator, zipCodeValidator, emailValidator } from "../../Utilities";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import {  ValidatedInput } from "../../Components";
import {  updateVCardInformation } from "./ProfileSlice";
import { userInformation} from '../Auth/AuthSlice';

import { statesList } from "../Register/RegistrationForm";
import { DropDownList } from '@progress/kendo-react-dropdowns'

function EditVCardDetails({ close, user }) {
  const dispatch = useDispatch();
  const[imgString, setImgString] = React.useState(user && user.photo);
  const[error, setError] = React.useState(false);
  const [states, setStates] = React.useState(user ? user.state : 'State');
  const reduxUserInformation = useSelector(userInformation);


  const handleChange = (event) => {
     setStates(event.target.value);
   };



  const handleImageChange = (event) => {
    setError(false);
    const file = event.target.files[0];
    if(file.type.split("/")[0] !== "image"){
      setError(true);
      return
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImgString(reader.result);
    };
  };

  const handleSubmit = (dataItem) => { 
    console.log(dataItem);
      let payload = JSON.parse(JSON.stringify(dataItem.values));
      payload.id = reduxUserInformation.id;
      payload.photo = imgString;
      payload.country = "USA"
      payload.state = states;
      dispatch(updateVCardInformation(payload));
      close();
  };

  return (
    <Form
      onSubmitClick={handleSubmit}
      initialValues={user}
      render={(formRenderProps) => (
        <FormElement>
          <div className="mb-3">
            <div className="w-full flex justify-between">
              <div>
                <p className="mb-2">Choose New Photo</p>
              <input type="file" id="fileInput" accept="image/*" onChange={handleImageChange} placeholder = "Select"/>
              <p>{error && "Please upload valid image file."}</p>
              </div>
              <div>
                {(user && user.photo) &&<img src = {imgString} width = "150" alt ="Profile"/>}
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"firstName"}
              label="First Name"
              component={Input}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"lastName"}
              label="Last Name"
              component={Input}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"company"}
              label="Company"
              component={Input}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"title"}
              label="Title"
              component={Input}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"phoneNumber"}
              label="Office Phone"
              component={ValidatedInput}
              validator={phoneNumberValidator}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"mobile"}
              label="Mobile Phone"
              component={ValidatedInput}
              validator={mobileValidator}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"address"}
              label="Address"
              component={Input}
            />
          </div>
          <div className='mb-3'>
            <Field
            type={'text'}
            name={'city'}
            label='City'
            component={Input}
            />
          </div>
          <div className='mb-6'>
            <Field
            type={'text'}
            name={'zipCode'}
            label='Zip Code'
            component={ValidatedInput}
            validator={zipCodeValidator}
            />
          </div>
          <div className='mb-3'>
              <DropDownList data={statesList} value={states} onChange = {handleChange}/>
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"website"}
              label="Website"
              component={ValidatedInput}
              validator={websiteValidator}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"linkedin"}
              label="Linkedin"
              component={ValidatedInput}
              validator={websiteValidator}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"email"}
              name={"emailAddress"}
              label="Email Address"
              component={ValidatedInput}
              validator={emailValidator}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"note"}
              label="Notes"
              component={Input}
            />
          </div>
          <div className="mb-3 flex justify-end">
            <Button primary={true} onClick={close}>
              Cancel
            </Button>
            <Button
              primary={true}
              type={"submit"}
              disabled={!formRenderProps.valid || states == 'State'}
              className="ml-3"
            >
              Submit
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
}

export default EditVCardDetails;
