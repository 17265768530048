import React from "react";
import { useDispatch } from "react-redux";
import { emailValidator, phoneNumberValidator, zipCodeValidator } from "../../Utilities";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { ValidatedInput } from "../../Components";
import { updateUser } from "./ProfileSlice";
import { statesList } from "../Register/RegistrationForm";
import { DropDownList } from '@progress/kendo-react-dropdowns'
import * as api from '../../../api';

function EditDetails({ close, user }) {
  const dispatch = useDispatch();
  const [states, setStates] = React.useState(user.state);

  const handleChange = (event) => {
     setStates(event.target.value);
   };

  const handleSubmit = (dataItem) => {
      let payload = JSON.parse(JSON.stringify(dataItem.values));
      payload.state = states;
      dispatch(updateUser(payload));
      close();

  };
  return (
    <Form
      onSubmitClick={handleSubmit}
      initialValues={user}
      render={(formRenderProps) => (
        <FormElement>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"firstName"}
              label="First Name"
              component={Input}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"lastName"}
              label="Last Name"
              component={Input}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"email"}
              name={"emailAddress"}
              label="Email Address"
              component={ValidatedInput}
              validator={emailValidator}
            />
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"address"}
              label="Address"
              component={Input}
            />
          </div>
          <div className='mb-3'>
            <Field
            type={'text'}
            name={'city'}
            label='City'
            component={Input}
            />
          </div>
          <div className='mb-6'>
            <Field
            type={'text'}
            name={'zipCode'}
            label='Zip Code'
            component={ValidatedInput}
            validator={zipCodeValidator}
            />
          </div>
          <div className='mb-3'>
              <DropDownList name="state" data={statesList} value={states} onChange = {handleChange}/>
          </div>
          <div className="mb-3">
            <Field
              type={"text"}
              name={"phoneNumber"}
              label="Phone Number"
              component={ValidatedInput}
              validator={phoneNumberValidator}
            />
          </div>
          <div className="mb-3 flex justify-end">
            <Button primary={true} onClick={close}>
              Cancel
            </Button>
            <Button
              primary={true}
              type={"submit"}
              disabled={!formRenderProps.valid}
              className="ml-3"
            >
              Submit
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
}

export default EditDetails;
