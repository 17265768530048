import React from 'react'

const RegistrationText = () => {
  return (
    <div>
      <p className='text-lg md:text-2xl xl:text-4xl font-bold text-gray-800'>
        Hey!<br></br> 
        Let's register your tag.
      </p>
      <p className='text-md text-gray-600 py-4'>
        Fill in the details you want to appear when someone finds and scans your luggage tag.  Why would you leave your shit somewhere in the first place?
      </p>
    </div>
  )
}

export default RegistrationText
