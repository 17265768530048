export const hasToken = () => {
  let token = getJwtToken();

  if(token) {
    return true;
  }

  return false;
}

export const login = token => {
  setJwtToken(token)
}

export const logout = token => {
  removeJwtToken(token)
}

const ValidateToken = token => {
  return true;
}

const setJwtToken = token => {
  window.localStorage.setItem('login_token', token)
}

const getJwtToken = () => {
  return window.localStorage.getItem('login_token')
}

export const removeJwtToken = () => {
  window.localStorage.removeItem('login_token')
}

export const emailValidator = value => {
  let emailRegex = new RegExp(/\S+@\S+\.\S+/)
  return emailRegex.test(value) ? '' : 'Please enter a valid email'
}

export const passwordValidator = value => {
  let passwordRegex = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
  return passwordRegex.test(value) ? '' : 'Password must be atleast 8 characters long, including uppercase, lowercase letters and numbers'
}

export const comparePassword = (password, confirmPassword) => {
  return password === confirmPassword ? '' : 'Passwords do not match'
}

export const phoneNumberValidator = value => {
  return value?.length === 10 ? '' : 'Please enter a valid phone number'
}

export const mobileValidator = value =>{
  return (value?.length ===0 || value?.length === 10) ? '' : 'Please enter a valid phone number'
}

export const zipCodeValidator = value => {
  var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  return isValidZip ? '' : 'Please enter a valid zip code.'
}

export const  websiteValidator= (string) => {
  let givenURL;
  try {
      givenURL = new URL(string);
  } catch (error) {
    return false;  
  }
  return givenURL.protocol === "http:" || givenURL.protocol === "https:";
}