import React, {useState} from 'react';
import { Button } from '@progress/kendo-react-buttons';
import EditDetails from '../screens/Profile/EditDetails';
import UpIcon from '../images/chevron-up.svg';
import DownIcon from '../images/chevron-down.svg';

const UserDetails = ({userInfo, isLoggedInUser}) => {
    const [editDetails, setEditDetails] = useState(false);
    const [showInfo, setShowInfo] = React.useState(false);
    return(
        <div className='flex-col'>
            <div className='text-xl xl:text-3xl md:text-2xl text-gray-700 mb-5 uppercase'>
                <span>{userInfo?.firstName + ' ' + userInfo?.lastName}</span>
            </div>
            <div className='w-full'>
                <div className="w-full flex justify-between">
                    <span className='text-gray-500 text-xl'>Contact Information</span>
                    {isLoggedInUser && !showInfo && <img src = {DownIcon} alt="Down" onClick={()=> setShowInfo(!showInfo)} style={{cursor: "pointer"}}/>}
                    {isLoggedInUser && showInfo && <img src = {UpIcon} alt="Up" onClick={()=> setShowInfo(!showInfo)}  style={{cursor: "pointer"}}/>}
                    
                </div>
                <hr className='mb-5 mt-2' /> 
                {!editDetails ? <>
                    {showInfo && <> 
                        <div className='mb-5 text-gray-700'>
                            <p>
                            <strong>Email: </strong>
                                {userInfo?.emailAddress}
                            </p>
                        </div>
                        <div className='mb-5 text-gray-700'>
                            <p>
                            <strong>Address: </strong>
                                {userInfo?.address + ', ' + userInfo?.city + ', ' + userInfo?.zipCode+ ', ' + userInfo?.state}
                            </p>
                        </div> 
                        <div className='mb-5 text-gray-700'>
                            <p>
                                <strong>Phone: </strong>
                                {userInfo?.phoneNumber}
                            </p>
                        </div>
                        <div className="text-right">
                        {isLoggedInUser && !editDetails && <Button primary={true} onClick={()=> setEditDetails(true)}>Edit Contact Information</Button>}
                        </div>
                        </>
                    }
                </>: 
                <EditDetails close={()=>setEditDetails(false)} user={userInfo}/>}
            </div>

        </div>
    );
};

export default UserDetails;