import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LandingImage from '../../images/landingImage.svg'
import LandingText from './LandingText'
import LoginForm from './LoginForm'
import { errorMessage } from './LandingSlice'
import { Error } from '@progress/kendo-react-labels'
import { EoLogo } from '../../Components';
import { isLoggedIn } from '../Auth/AuthSlice';
import Banner from '../../Components/Banner';

const Landing = () => {

  const reduxErrorMessage = useSelector(errorMessage);
  const reduxIsLoggedIn = useSelector(isLoggedIn);
  const history = useHistory();

  useEffect(() => {

    if (reduxIsLoggedIn) {
      history.push('/profile');
    }

  }, [reduxIsLoggedIn])

  return (
    <div className='w-full min-h-screen flex items-center p-8 landing-bg'>
      <div className='w-full p-8 md:p-12 bg-white max-w-6xl rounded-lg shadow-lg flex flex-wrap justify-center lg:justify-between mx-auto relative'>
        <div className='my-4'>
          <div className='mb-8'>
            <EoLogo />
          </div>
          <img
            src={LandingImage}
            className='max-w-md w-full'
            alt='baggage search illustration'
          />
        </div>
        <div className='max-w-sm flex-grow my-4 flex flex-col justify-center'>
          <LandingText />
          <div>
            {reduxErrorMessage?.length > 0 && <Error>{reduxErrorMessage}</Error>}
            <LoginForm />
          </div>
        </div>
        <Banner/>
      </div>
    </div>
  )
}

export default Landing
