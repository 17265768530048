import React from 'react'

const LandingText = () => {
  return (
    <div>
      <p className='text-lg md:text-2xl xl:text-4xl font-bold text-gray-800'>
        Welcome!
      </p>
      <p className='text-md text-gray-600 py-4'>
        Login in to access your EONJ luggage tag dashboard.  Set your preferences and track your tags!
      </p>
    </div>
  )
}

export default LandingText
