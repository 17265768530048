import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../../api';

const initialState = {
    registered: false,
    registrationError: null
}

const RegisterSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        setRegistered: (state, action) => {
            state.registered = action.payload;
        },
        setRegistrationError: (state, action) => {
            state.registrationError = action.payload;
        }
    }
});

export const isRegistered = (state) => state.register.registered;
export const registrationError = (state) => state.register.registrationError;

export const registerUser = (payload) => (dispatch, getState) => {
    api.registerUser(payload).then((res) => {
        dispatch(setRegistered(true));
        dispatch(setRegistrationError(null));
    }).catch((error) => {
        dispatch(setRegistered(false));
        dispatch(setRegistrationError('Something went wrong while trying to register. Please try again.'));
    })
}

export const { setRegistered, setRegistrationError } = RegisterSlice.actions;

export default RegisterSlice.reducer;