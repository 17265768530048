import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../../api';
import {removeJwtToken} from '../../Utilities';
const initialState = {
    isLoggedIn: false,
    user: null,
    error: '',
    isSuccess: false
}

export const AuthSlice = createSlice({
    name : 'auth',
    initialState,

    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setIsSuccess: (state, action)=> {
            state.isSuccess = action.payload;
        },
        signout: (state, action) => {
            state = initialState;
        }
    }
});

export const getUserInformation = (payload) => (dispatch, getState) => {
    api.getUserInformation(payload).then((res) => {
        dispatch(setIsLoggedIn(true));
        dispatch(setUser(res.data));
    }).catch((e) => {
        dispatch(setIsLoggedIn(false));
        dispatch(setUser(null));
    })
}
export const forgotPassword = ({emailAddress}) => (dispatch, getState) => {
    api.forgotPassword(emailAddress).then((res) => {
        dispatch(setError(''));
        dispatch(setIsSuccess(true));
    }).catch((e) => {
        dispatch(setError("Something went wrong. Please try again."));
    })
}

export const resetPassword = (payload) => (dispatch, getState) => {
    api.resetPassword(payload).then((res) => {
        dispatch(setError(''));
        dispatch(setIsSuccess(true));
    }).catch((e) => {
        dispatch(setError("Something went wrong. Please try again."));
    })
}
export const singoutUser = ()=> (dispatch, getState) => {
    dispatch(signout());
    removeJwtToken();
}

export const isLoggedIn = (state) => state.auth.isLoggedIn;
export const userInformation = (state) => state.auth.user;
export const isSuccess = (state)=>state.auth.isSuccess;
export const error = (state)=>state.auth.error;

export const { setIsLoggedIn, setUser, signout, setError, setIsSuccess } = AuthSlice.actions;

export default AuthSlice.reducer;