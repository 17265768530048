import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScanHistoryInformation, scanHistory } from "./ProfileSlice";
import HistoryGrid from "./HistoryGrid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import HistoryMap from "./HistoryMap";

const ScanHistory = () => {
  const dispatch = useDispatch();
  const scanHistoryData = useSelector(scanHistory);

  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  useEffect(() => {
    dispatch(getScanHistoryInformation());
  }, []);

  return (
    <>
      <span className="text-gray-500 text-xl">Scan History</span>
      <hr className="mb-5 mt-2" />
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title="Map View">
          <HistoryMap data={scanHistoryData} />
        </TabStripTab>
        <TabStripTab title="Grid View">
          <HistoryGrid data={scanHistoryData} />
        </TabStripTab>
      </TabStrip>
    </>
  );
};
export default ScanHistory;
