import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Loader } from "@progress/kendo-react-indicators";
import { hasUserRegistered, trackerId } from "./HomeSlice";
import { EoLogo } from "../../Components";
import Banner from '../../Components/Banner';

function RedirectByStatus({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const validTrackerId = useSelector(trackerId);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    let params = queryString.parse(location.search);
    if (params.trackerId) {
      dispatch(hasUserRegistered(params.trackerId));        
    } else {
      history.push("/login");
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (validTrackerId === -1) {
        let params = queryString.parse(location.search);
      history.push(`/register/${params.trackerId}`);
    }
    setIsLoading(false);
  }, [validTrackerId]);

  return (
    <div className="w-full min-h-screen flex p-8 landing-bg">
      <div className="my-auto p-8 md:p-12 bg-white max-w-6xl rounded-lg shadow-lg flex-col flex-wrap justify-center lg:justify-between mx-auto relative" style={{minWidth: '300px'}}>
        <div className="my-4">
          <div className="mb-8">
            <EoLogo />
          </div>
          <div className="mb-8 max-w-6xl">
            {isLoading ? <Loader size="large" /> : { ...children }}
          </div>
        </div>
        <Banner/>
      </div>
    </div>
  );
}

export default RedirectByStatus;
