import React from "react";
import UpIcon from '../../images/chevron-up.svg';
import DownIcon from '../../images/chevron-down.svg';
import { Button } from '@progress/kendo-react-buttons';
import EditVCardDetails from "./EditVCardDetails";

const VCardDetails = ({userInfo, isLoggedInUser}) =>{
    const [showInfo, setShowInfo] = React.useState(false);
    const [editDetails, setEditDetails] = React.useState(false);
    const vCardInfoExists = userInfo!=null;
    const getUrl = (value) =>{
        if (value.indexOf("http://") == 0 || value.indexOf("https://") == 0) {
            return value;
        }
        else{
            return `https://${value}`;
        }
    }


return (
    <div className='mt-5 w-full'>
        <div className="w-full flex justify-between">
            <span className='text-gray-500 text-xl'>VCard Information</span>
            {isLoggedInUser && !showInfo && <img src = {DownIcon} alt="Down" onClick={()=> setShowInfo(!showInfo)} style={{cursor: "pointer"}}/>}
            {isLoggedInUser && showInfo && <img src = {UpIcon} alt="Up" onClick={()=> setShowInfo(!showInfo)}  style={{cursor: "pointer"}}/>}
        </div>
        <hr className='mb-5 mt-2' /> 
        {showInfo && 
            <>
                <div className='mb-5 text-gray-700'>
                    {!editDetails ? <>
                        {(vCardInfoExists)? 
                        <div className="w-full flex justify-between">
                            <div>
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Name: </strong>
                                        {userInfo?.firstName ? userInfo?.firstName +' ' + userInfo?.lastName : '------'}
                                    </p>
                                </div>  
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Company: </strong>
                                        {userInfo?.company ? userInfo?.company : '------'}
                                    </p>
                                </div>
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Title: </strong>
                                        {userInfo?.title ? userInfo?.title : '------'}
                                    </p>
                                </div>    
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Office Phone: </strong>
                                        {userInfo?.phoneNumber ? userInfo?.phoneNumber : '------'}
                                    </p>
                                </div>                                  
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Mobile Phone: </strong>
                                        {userInfo?.mobile ? userInfo?.mobile : '------'}
                                    </p>
                                </div>   
                                <div className='mb-5 text-gray-700'>
                            <p>
                            <strong>Office Address: </strong>
                                {userInfo?.address + ', ' + userInfo?.city + ', ' + userInfo?.zipCode+ ', ' + userInfo?.state}
                            </p>
                        </div>   
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Website: </strong>
                                        
                                        {userInfo?.website ? <a href = {getUrl(userInfo?.website)} target= "_blank" rel = "noreferrer"><u>{userInfo?.website}</u> </a> : '------'}
                                    </p>
                                </div>    
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Linkedin: </strong>
                                        {userInfo?.linkedin ? <a href = {getUrl(userInfo?.linkedin)} target= "_blank" rel = "noreferrer"><u>{userInfo?.linkedin} </u> </a> : '------'}
                                    </p>
                                </div>   
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Email: </strong>
                                        {userInfo?.emailAddress ? userInfo?.emailAddress : '------'}
                                    </p>
                                </div>  
                                <div className='mb-5 text-gray-700'>
                                    <p>
                                        <strong>Notes: </strong>
                                        {userInfo?.note  ? userInfo?.note : '------'}
                                    </p>
                                </div>   
                                
                            </div>
                            <div>
                                {userInfo.photo && <img src = {userInfo.photo} width = "150" alt ="Profile"/>}
                            </div>
                            </div>
                            : 
                            <p>You have not added your VCard Information.</p>
                        }
                        </>:
                        <EditVCardDetails close={()=>setEditDetails(false)} user = {userInfo} />
                    }
                </div>
                <div className="text-right">
                    {isLoggedInUser && !editDetails && <Button primary={true} onClick={()=> setEditDetails(true)}>{vCardInfoExists? 'Edit VCard Information' : 'Add VCard Information'}</Button>}
                </div>
            </>
        }
    </div>
)
}

export default VCardDetails;