import React from 'react';
import PrivateRoute from './PrivateRoute';
import { Home } from '../screens/Home';
import { Switch, Route } from 'react-router-dom';
import { Register } from '../screens/Register';
import { Landing } from '../screens/Landing';
import { Profile } from '../screens/Profile';
import { Error404 } from '../screens/Error404';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ResetPassword';

const Routes = () => {
    return(
            <Switch>
                <Route path='/' exact component = { Home }/>
                <Route path='/register/:trackerId' exact component = { Register } />
                <Route path='/login' exact component = { Landing } />
                <Route path='/forgot-password' exact component = { ForgotPassword } />
                <Route path='/reset-password' exact component = { ResetPassword } />
                <PrivateRoute path='/profile' exact component = { Profile } />
                <Route path='*' component= { Error404 } />
            </Switch>
    );
};

export default Routes;