import { createSlice } from "@reduxjs/toolkit";
import * as api from "../../../api";
import {setUser} from "../Auth/AuthSlice";

const initialState = {
  userInformation: null,
  scanHistory: [],
  errorText: '',
  vCardInformation: null,
};

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,

  reducers: {
    setUserInformation: (state, action) => {
      state.userInformation = action.payload;
    },
    setVCardInformation: (state, action) => {
      state.vCardInformation = action.payload;
    },
    setScanHistory: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        let tempData = [...action.payload];
        tempData.forEach((element) => {
          element.dateCreated = new Date(element.dateCreated);
        });
        state.scanHistory = tempData;
      } else {
        state.scanHistory = [];
      }
    },
    setErrorText: (state, action) => {
      state.errorText = action.payload;
    }
  },
});

export const getScanHistoryInformation = () => (dispatch, getState) => {
  let trackerId = getState().auth.user.id;
  api
    .getScanHistory(trackerId)
    .then((res) => {
      dispatch(setScanHistory(res.data));
    })
    .catch((e) => {
      dispatch(setScanHistory([]));
    });
};

export const getVCardInformation = () => (dispatch, getState) =>{
  let userId = getState().auth.user.id;
  api.getVCardInformation(userId).then((res)=>{
    dispatch(setVCardInformation(res.data))
  })
}

export const updateVCardInformation = (vCardInfo) => (dispatch, getState) =>{
  api.updateVCardInformation(vCardInfo).then((res)=>{
    dispatch(setVCardInformation(res.data));
  })
  .catch((e) => {
    dispatch(setErrorText("Something went wrong"));
  });
}

export const updateUser = (user) => (dispatch, getState) => {
  api
    .updateUser(user)
    .then((res) => {
      dispatch(setUser(res.data));
    })
    .catch((e) => {
      dispatch(setErrorText("Something went wrong"));
    });
};



export const userInformation = (state) => state.profile.userInformation;
export const scanHistory = (state) => state.profile.scanHistory;
export const vCardInformation = (state) => state.profile.vCardInformation;
export const { setUserInformation, setScanHistory, setErrorText,setVCardInformation } = ProfileSlice.actions;

export default ProfileSlice.reducer;
