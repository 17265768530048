import { configureStore } from '@reduxjs/toolkit';
import { HomeSlice } from './screens/Home';
import { RegisterSlice } from './screens/Register';
import { LandingSlice } from './screens/Landing';
import { ProfileSlice } from './screens/Profile';
import { AuthSlice } from './screens/Auth';

export const store = configureStore({
  reducer: {
    home: HomeSlice,
    register: RegisterSlice,
    landing: LandingSlice,
    profile: ProfileSlice,
    auth: AuthSlice
  },
});
