import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

const initialDataState = {
  skip: 0,
  take: 10,
};
const HistoryGrid = ({data}) => {
  const [page, setPage] = useState(initialDataState);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const EditCommandCell = (props) => {
    let dataItem = props.dataItem;
    let mpasUrl = `https://www.google.com/maps/place/${dataItem.latitude}+${dataItem.longitude}`;
    return (
      <td>
        <a href={mpasUrl} target="_blank">
          <Button primary={true} look="flat">
            View in Maps
          </Button>
        </a>
      </td>
    );
  };

  return (
      <Grid
        style={{
          height: "400px",
        }}
        data={data.slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={data.length}
        pageable={true}
        onPageChange={pageChange}
      >
        <GridColumn field="dateCreated" filter="date" title="Date Scanned" format="{0:F}" />
        <GridColumn field="latitude" title="Latitude" />
        <GridColumn field="longitude" title="Longitude" />
        <GridColumn field="address" title="Address" />
        <GridColumn cell={EditCommandCell} />
      </Grid>
  );
};
export default HistoryGrid;
