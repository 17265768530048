import { createSlice } from "@reduxjs/toolkit";
import { setIsLoggedIn, setUser } from "../Auth/AuthSlice";
import { login } from "../../Utilities";
import * as api from "../../../api";

const initialState = {
  loginFailure: false,
  errorMessage: null,
};

export const LandingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
  },
});

export const { setErrorMessage } = LandingSlice.actions;

export const loginUser = (payload) => (dispatch, getState) => {
  api
    .login(payload)
    .then((res) => {
      dispatch(setIsLoggedIn(true));
      dispatch(setUser(res.data));
      if(res?.data?.access_token){ 
        login(res.data.access_token)
      }
      dispatch(setErrorMessage(null));
    })
    .catch((e) => {
      dispatch(setIsLoggedIn(false));
      dispatch(setErrorMessage("Username or password is not correct."));
    });
};
export const loginWithToken = () => (dispatch) => {
  api
    .loginWithToken()
    .then((res) => {
      dispatch(setIsLoggedIn(true));
      dispatch(setUser(res.data));
      if(res?.data?.access_token){ 
        login(res.data.access_token)
      }
      dispatch(setErrorMessage(null));
    })
    .catch((e) => {
      dispatch(setIsLoggedIn(false));
    });
};

export const errorMessage = (state) => state.landing.errorMessage;

export default LandingSlice.reducer;
