import React, { useEffect, useState } from "react";
import { EoLogo } from "../../Components";
import RsetPasswordImage from "../../images/resetPassword.svg";
import { useDispatch, useSelector } from "react-redux";
import { passwordValidator } from "../../Utilities";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { ValidatedInput } from "../../Components";
import { useHistory, useLocation } from "react-router-dom";
import {
  resetPassword,
  setIsSuccess,
  error,
  isSuccess,
} from "../Auth/AuthSlice";
import { Error } from "@progress/kendo-react-labels";
import queryString from "query-string";

function ResetPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const _error = useSelector(error);
  const _isSuccess = useSelector(isSuccess);
  const [token, setToken] = useState("");

  useEffect(() => {
    let params = queryString.parse(location.search);
    if (params.token) {
      setToken(params.token);
    } else {
      history.push("/login");
    }
  }, [location]);

  const handleSubmit = async (dataItem) => {
    dispatch(resetPassword({ ...dataItem, token: token }));
  };

  return (
    <div className="w-full min-h-screen flex items-center p-8 landing-bg">
      <div className="w-full p-8 md:p-12 bg-white max-w-2xl rounded-lg shadow-lg flex flex-wrap justify-center lg:justify-between mx-auto">
        <div className="my-4">
          <div className="mb-8">
            <EoLogo />
          </div>
          {!_isSuccess && <div className="w-64 mx-4">
            <img
              src={RsetPasswordImage}
              className="w-full"
              alt="password illustration"
            />
          </div>}
        </div>
        <div className="max-w-md flex-grow m-4 flex flex-col justify-center">
          {!_isSuccess ? (
            <>
              <p className="text-lg md:text-xl font-bold text-gray-800">
                Reset Password
              </p>
              {_error.length > 0 && <Error>{_error}</Error>}
              <Form
                onSubmit={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    <div className="mb-3">
                      <Field
                        type={"password"}
                        name={"password"}
                        label="Password"
                        component={ValidatedInput}
                        validator={passwordValidator}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        type={"password"}
                        name={"confirmPassword"}
                        label="Confirm Password"
                        component={ValidatedInput}
                        validator={passwordValidator}
                      />
                    </div>
                    <div className="mb-3 flex justify-between items-end">
                      <Button
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        Submit
                      </Button>
                      <p
                        tabIndex="0"
                        className="text-right text-primary underline italic cursor-pointer"
                        onClick={() => history.push("/login")}
                      >
                        Login
                      </p>
                    </div>
                  </FormElement>
                )}
              />
            </>
          ) : (
            <>
              <p className="md:text-lg font-bold text-gray-800 text-center">
                Reset Password Successful.
              </p>
              <p
                tabIndex="0"
                className="text-center underline cursor-pointer uppercase text-xl tex-blue-500"
                onClick={() => {
                  history.push("/login");
                  dispatch(setIsSuccess(false));
                }}
              >
                Back to login
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
