import React from 'react';
import { EoLogo } from '../../Components';
import ErrorImage from '../../images/404.svg';
import Banner from '../../Components/Banner';
const Error404 = () => {
    return(
        <div className='w-full min-h-screen flex items-center p-8 landing-bg'>
        <div className='p-8 w-full md:p-12 bg-white max-w-6xl rounded-lg shadow-lg flex flex-wrap justify-center lg:justify-between mx-auto relative'>
          <div className='my-4'>
            <EoLogo />
            <div className='max-w-sm flex-grow my-4 flex-col justify-center text-gray-500'>
                <div className='text-2xl lg:text-4xl flex-col mb-3'>
                    <p>404</p>
                    <p>Page Not Found</p>
                </div>
                <div>
                    <span>Oops, we can't find that page. Please <a href='/Login' className='text-blue-500'>login</a>.</span>
                </div>
            </div>
          </div>
          <div>
            <img src={ErrorImage}
              className='max-w-md w-full'
              alt='under construction'/>
          </div>
          <Banner/>
        </div>
      </div>
    );
};

export default Error404;