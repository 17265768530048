import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";

const Pointer = () => (
  <span className="k-icon k-i-marker-pin-target text-red-500"></span>
);

function HistoryMap({ data }) {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [defaultCenter, setDefaultCenter] = useState(null);

  return (
    <div className="w-screen max-w-xs sm:max-w-sm md:max-w-xl lg:max-w-2xl h-96">
      {data.length > 0 && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
          defaultCenter={{ lat: data[0].latitude, lng: data[0].longitude }}
          yesIWantToUseGoogleMapApiInternals
          defaultZoom={13}
        >
          {data.map((scan, index) =>            
            <Pointer lat={scan.latitude} lng={scan.longitude} key={index}/>
          )}
        </GoogleMapReact>
      )}
    </div>
  );
}

export default HistoryMap;
