import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoggedIn } from "./AuthSlice";
import { loginWithToken } from "../Landing/LandingSlice";
import { hasToken } from "../../Utilities";
import LoaderScreen from "../../Components/LoaderScreen";

const Authorized = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loginCheck().then(() => {
      setIsLoading(false);
    });
  }, []);

  const loginCheck = () => {
    return new Promise((resolve, reject) => {
      if (hasToken()) {
        dispatch(loginWithToken());
      } else {
        dispatch(setIsLoggedIn(false));
      }
      resolve();
    });
  };

  return isLoading ? <LoaderScreen /> : { ...children };
};

export default Authorized;
